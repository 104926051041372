import { useCallback } from 'react'
import { ChainId, Currency, Token, CurrencyAmount, Trade } from '@gizzard-dex/sdk'
import styled from 'styled-components'
import {
  Button,
  Text,
  ErrorIcon,
  // ArrowUpIcon,
  AddIcon,
  Flex,
  Box,
  Link,
  // Spinner,
  Modal,
  InjectedModalProps,
  ConfirmChecked,
  OpenNewIcon,
  MetamaskIcon,
} from 'packages/uikit'
import { registerToken } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from 'state/types'
import { CurrencyLogo } from 'components/Logo'
// import { RowFixed } from '../Layout/Row'
import { useRouter } from 'next/router'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBscScanLink } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  position: relative;
`

const Section = styled(AutoColumn)`
  padding: 0 0;
`

const ConfirmedIcon = styled(ColumnCenter)`
  width: auto;
  margin: auto;
  border-radius: 100%;
  margin: 0 0 16px;
`

const StyledFlex = styled(Flex)`
  justify-content: center;
  padding: 0 0 25px;
`

const StyledText = styled(Text).attrs({ fontSize: '16px', textAlign: 'center' })`
  white-space: nowrap;
`

const HeadPosition = styled.div`
  transform: translateY(-25px);
  position: 9;
`

function ConfirmationPendingContent({
  pendingText,
  onDismiss,
  inputCurrency,
  outputCurrency,
}: {
  pendingText: string
  onDismiss: () => void
  inputCurrency: CurrencyAmount
  outputCurrency: CurrencyAmount
}) {
  const { t } = useTranslation()

  const router = useRouter()

  // console.log(parseFloat(inputCurrency.toExact()))

  return (
    <Wrapper>
      <Text textAlign="center" fontSize="25px">
        {t('Waiting For Confirmation')}
      </Text>
      <StyledFlex>
        {/* <Spinner /> */}
        <img src="/images/waiting-transaction.gif" alt="waiting-transaction" />
      </StyledFlex>
      <AutoColumn gap="12px" justify="center">
        {/* <StyledText color="black">{t('Waiting For Confirmation')}</StyledText> */}
        <Flex justifyContent="space-between" alignItems="center" width="100%" style={{ gap: '12px' }}>
          <Flex alignItems="center" width="calc(45% - 12px)">
            <CurrencyLogo currency={inputCurrency?.currency} />
            <Text textAlign="center" ml="8px">
              {parseFloat(inputCurrency?.toExact()) < 1
                ? inputCurrency?.toSignificant(6) ?? '0.00'
                : inputCurrency?.toFixed(2)}
            </Text>
          </Flex>

          {router.pathname === '/swap' ? (
            <Flex justifyContent="center" width="24px" height="24px" borderRadius="50%">
              <img src="/images/swap-arrow.svg" alt="swap-arrow" />
            </Flex>
          ) : (
            <Flex justifyContent="center" border="1px solid black" width="24px" height="24px" borderRadius="50%">
              <AddIcon />
            </Flex>
          )}

          <Flex alignItems="center" justifyContent="flex-end" width="calc(45% - 12px)">
            <Text textAlign="center" mr="8px">
              {parseFloat(outputCurrency?.toExact()) < 1
                ? outputCurrency?.toSignificant(6) ?? '0.00'
                : outputCurrency?.toFixed(2)}
            </Text>
            <CurrencyLogo currency={outputCurrency?.currency} />
          </Flex>

          {/* <Text textAlign="center" fontSize="16px">
            {pendingText}
          </Text> */}
        </Flex>
        <Text textAlign="center">{t('Please comfirm in your wallet')}</Text>
      </AutoColumn>
      <Box padding={['16px 0']}>
        <Button width="100%" onClick={onDismiss}>
          {t('Close')}
        </Button>
      </Box>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const { library } = useActiveWeb3React()

  const { t } = useTranslation()

  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

  return (
    <Wrapper>
      <Section style={{ gap: '20px' }}>
        <Text textAlign="center" fontSize="25px">
          {t('Succcessful Transaction')}
        </Text>

        <ConfirmedIcon>
          <ConfirmChecked strokeWidth={0.5} width="145px" style={{ transform: 'translateX(20px)' }} />
        </ConfirmedIcon>
        <AutoColumn gap="0px" justify="center">
          {/* <StyledText color="black" bold mb="16px">
            {t('Successful Transaction')}
          </StyledText> */}
          {chainId && hash && (
            <Button
              minWidth={254}
              padding={0}
              variant="no-bg"
              as={Link}
              href={getBscScanLink(hash, 'transaction', chainId)}
              external
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
                borderRadius={30}
                border="1px solid black"
                padding="0 16px"
                position="relative"
              >
                {t('View on Explorer')}

                <Flex position="absolute" style={{ right: '0', transform: 'translateX(-15px)' }}>
                  <OpenNewIcon width={16} />
                </Flex>
              </Flex>
            </Button>
          )}

          {/* <Link
            color="black"
            external
            fontSize={['10px', , , , '14px']}
            href={getBscScanLink(hash, 'transaction', chainId)}
          >
            {t('View on Explorer')}
          </Link> */}

          {currencyToAdd && library?.provider?.isMetaMask && (
            <Button
              mt="10px"
              minWidth={254}
              variant="no-bg"
              onClick={() =>
                registerToken(
                  token.address,
                  token.symbol,
                  token.decimals,
                  token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                )
              }
              style={{ background: 'rgba(252, 200, 200, 0.8)' }}
            >
              {t('Add %asset% to Metamask', { asset: currencyToAdd?.symbol ?? 'GIZ' })}
              <MetamaskIcon width="24px" ml="6px" />
            </Button>
          )}
          <Button width="100%" onClick={onDismiss} mt="16px" mb="16px">
            {t('Close')}
          </Button>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn justify="center">
        <ErrorIcon width="48px" />
        <Text textAlign="center">{message}</Text>
      </AutoColumn>

      <Flex justifyContent="center" pt="24px" width="380px">
        <Button onClick={onDismiss} width="100%">
          {t('Dismiss')}
        </Button>
      </Flex>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
  isErrorMessage?: string | undefined
  inputCurrency?: CurrencyAmount
  outputCurrency?: CurrencyAmount
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
  isErrorMessage,
  inputCurrency,
  outputCurrency,
}) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal
      title={attemptingTxn || hash ? '' : title}
      headerBackground="gradients.cardHeader"
      onDismiss={handleDismiss}
      maxWidth={isErrorMessage ? '420px' : '420px'}
      bodyPadding="0 24px 24px"
    >
      {attemptingTxn ? (
        <ConfirmationPendingContent
          pendingText={pendingText}
          onDismiss={handleDismiss}
          inputCurrency={inputCurrency}
          outputCurrency={outputCurrency}
        />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
