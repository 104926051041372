import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 143 142">
      <mask
        id="mask0_3269_4982"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="108"
        width="86"
        height="35"
      >
        <path d="M0 108.48H85.2162V142.001H0V108.48Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3269_4982)">
        <mask
          id="mask1_3269_4982"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="108"
          width="85"
          height="34"
        >
          <path
            d="M74.6341 108.817C77.8372 111.416 79.6845 114.443 79.6845 117.67C79.6845 127.382 63.0302 135.257 42.4897 135.257C21.9475 135.257 5.29317 127.382 5.29317 117.67C5.29317 114.443 7.13186 111.416 10.3436 108.817C3.92358 112.334 0.0390625 116.915 0.0390625 121.926C0.0390625 133.011 19.0439 141.999 42.4897 141.999C65.9337 141.999 84.9369 133.011 84.9369 121.926C84.93 116.915 81.0472 112.334 74.6341 108.817Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_3269_4982)">
          <path d="M0.0390625 108.817V141.999H84.9369V108.817H0.0390625Z" fill="url(#paint0_linear_3269_4982)" />
        </g>
      </g>
      <mask
        id="mask2_3269_4982"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="142"
        height="120"
      >
        <path d="M1.0625 0H142.321V119.436H1.0625V0Z" fill="white" />
      </mask>
      <g mask="url(#mask2_3269_4982)">
        <mask
          id="mask3_3269_4982"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="0"
          width="142"
          height="120"
        >
          <path
            d="M39.541 90.319C34.7833 79.2167 24.4874 67.7172 18.492 61.5917L1.10156 82.187C22.0786 101.016 28.8222 119.324 28.8222 119.324H56.1371C78.2047 28.7351 142.297 0.000976562 142.297 0.000976562C74.214 14.5649 39.541 90.319 39.541 90.319Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_3269_4982)">
          <path d="M1.10156 0.000976562V119.324H142.297V0.000976562H1.10156Z" fill="url(#paint1_linear_3269_4982)" />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3269_4982"
          x1="0.0389003"
          y1="125.408"
          x2="84.937"
          y2="125.408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCDB6" />
          <stop offset="0.0078125" stopColor="#FDCDB6" />
          <stop offset="0.015625" stopColor="#FDCCB6" />
          <stop offset="0.0234375" stopColor="#FDCBB6" />
          <stop offset="0.03125" stopColor="#FDCAB6" />
          <stop offset="0.0390625" stopColor="#FDC9B6" />
          <stop offset="0.046875" stopColor="#FDC8B6" />
          <stop offset="0.0546875" stopColor="#FDC7B6" />
          <stop offset="0.0625" stopColor="#FDC6B6" />
          <stop offset="0.0703125" stopColor="#FDC5B6" />
          <stop offset="0.078125" stopColor="#FDC4B6" />
          <stop offset="0.0859375" stopColor="#FDC3B7" />
          <stop offset="0.09375" stopColor="#FDC2B7" />
          <stop offset="0.101563" stopColor="#FDC1B7" />
          <stop offset="0.109375" stopColor="#FDC0B7" />
          <stop offset="0.117188" stopColor="#FDBFB7" />
          <stop offset="0.125" stopColor="#FDBEB7" />
          <stop offset="0.132813" stopColor="#FDBDB7" />
          <stop offset="0.140625" stopColor="#FDBCB7" />
          <stop offset="0.148438" stopColor="#FDBBB7" />
          <stop offset="0.15625" stopColor="#FDBAB7" />
          <stop offset="0.164063" stopColor="#FDB9B7" />
          <stop offset="0.171875" stopColor="#FDB8B7" />
          <stop offset="0.179688" stopColor="#FDB7B7" />
          <stop offset="0.1875" stopColor="#FDB6B7" />
          <stop offset="0.191406" stopColor="#FDB5B7" />
          <stop offset="0.195312" stopColor="#FDB4B7" />
          <stop offset="0.199219" stopColor="#FDB4B7" />
          <stop offset="0.203125" stopColor="#FDB3B7" />
          <stop offset="0.207031" stopColor="#FDB2B7" />
          <stop offset="0.210938" stopColor="#FDB2B7" />
          <stop offset="0.214844" stopColor="#FDB1B7" />
          <stop offset="0.21875" stopColor="#FDB1B7" />
          <stop offset="0.222656" stopColor="#FDB0B7" />
          <stop offset="0.226562" stopColor="#FDAFB7" />
          <stop offset="0.230469" stopColor="#FDAFB7" />
          <stop offset="0.234375" stopColor="#FDAEB7" />
          <stop offset="0.238281" stopColor="#FDADB7" />
          <stop offset="0.242188" stopColor="#FDADB7" />
          <stop offset="0.246094" stopColor="#FDACB7" />
          <stop offset="0.25" stopColor="#FDACB7" />
          <stop offset="0.253906" stopColor="#FDABB7" />
          <stop offset="0.257812" stopColor="#FDAAB7" />
          <stop offset="0.261719" stopColor="#FDAAB7" />
          <stop offset="0.265625" stopColor="#FDA9B7" />
          <stop offset="0.269531" stopColor="#FDA8B8" />
          <stop offset="0.273438" stopColor="#FDA8B8" />
          <stop offset="0.277344" stopColor="#FDA7B8" />
          <stop offset="0.28125" stopColor="#FDA7B8" />
          <stop offset="0.285156" stopColor="#FDA6B8" />
          <stop offset="0.289062" stopColor="#FDA5B8" />
          <stop offset="0.292969" stopColor="#FDA5B8" />
          <stop offset="0.296875" stopColor="#FDA4B8" />
          <stop offset="0.300781" stopColor="#FDA4B8" />
          <stop offset="0.304687" stopColor="#FDA3B8" />
          <stop offset="0.308594" stopColor="#FDA2B8" />
          <stop offset="0.3125" stopColor="#FDA2B8" />
          <stop offset="0.316406" stopColor="#FDA1B8" />
          <stop offset="0.320312" stopColor="#FDA0B8" />
          <stop offset="0.324219" stopColor="#FDA0B8" />
          <stop offset="0.328125" stopColor="#FD9FB8" />
          <stop offset="0.332031" stopColor="#FD9FB8" />
          <stop offset="0.335937" stopColor="#FD9EB8" />
          <stop offset="0.339844" stopColor="#FD9DB8" />
          <stop offset="0.34375" stopColor="#FD9DB8" />
          <stop offset="0.347656" stopColor="#FD9CB8" />
          <stop offset="0.351562" stopColor="#FD9CB8" />
          <stop offset="0.355469" stopColor="#FD9BB8" />
          <stop offset="0.359375" stopColor="#FD9AB8" />
          <stop offset="0.363281" stopColor="#FD9AB8" />
          <stop offset="0.367188" stopColor="#FE99B8" />
          <stop offset="0.371094" stopColor="#FE98B8" />
          <stop offset="0.375" stopColor="#FE98B8" />
          <stop offset="0.378906" stopColor="#FE97B8" />
          <stop offset="0.382812" stopColor="#FE97B8" />
          <stop offset="0.386719" stopColor="#FE96B8" />
          <stop offset="0.390625" stopColor="#FE95B8" />
          <stop offset="0.394531" stopColor="#FE95B8" />
          <stop offset="0.398438" stopColor="#FE94B8" />
          <stop offset="0.402344" stopColor="#FE94B8" />
          <stop offset="0.40625" stopColor="#FE93B8" />
          <stop offset="0.410156" stopColor="#FE92B8" />
          <stop offset="0.414062" stopColor="#FE92B8" />
          <stop offset="0.417969" stopColor="#FE91B8" />
          <stop offset="0.421875" stopColor="#FE90B8" />
          <stop offset="0.425781" stopColor="#FE90B8" />
          <stop offset="0.429688" stopColor="#FE8FB8" />
          <stop offset="0.433594" stopColor="#FE8FB8" />
          <stop offset="0.4375" stopColor="#FE8EB8" />
          <stop offset="0.441406" stopColor="#FE8DB8" />
          <stop offset="0.445312" stopColor="#FE8DB8" />
          <stop offset="0.449219" stopColor="#FE8CB8" />
          <stop offset="0.453125" stopColor="#FE8CB9" />
          <stop offset="0.457031" stopColor="#FE8BB9" />
          <stop offset="0.460938" stopColor="#FE8AB9" />
          <stop offset="0.464844" stopColor="#FE8AB9" />
          <stop offset="0.46875" stopColor="#FE89B9" />
          <stop offset="0.472656" stopColor="#FE88B9" />
          <stop offset="0.476562" stopColor="#FE88B9" />
          <stop offset="0.480469" stopColor="#FE87B9" />
          <stop offset="0.484375" stopColor="#FE87B9" />
          <stop offset="0.488281" stopColor="#FE86B9" />
          <stop offset="0.492188" stopColor="#FE85B9" />
          <stop offset="0.496094" stopColor="#FE85B9" />
          <stop offset="0.5" stopColor="#FE84B9" />
          <stop offset="0.503906" stopColor="#FE84B9" />
          <stop offset="0.507812" stopColor="#FE83B9" />
          <stop offset="0.511719" stopColor="#FE82B9" />
          <stop offset="0.515625" stopColor="#FE82B9" />
          <stop offset="0.523438" stopColor="#FE82B9" />
          <stop offset="0.527344" stopColor="#FE82BA" />
          <stop offset="0.53125" stopColor="#FE83BA" />
          <stop offset="0.535156" stopColor="#FE83BB" />
          <stop offset="0.539063" stopColor="#FE84BB" />
          <stop offset="0.542969" stopColor="#FE84BC" />
          <stop offset="0.546875" stopColor="#FE85BC" />
          <stop offset="0.550781" stopColor="#FE85BD" />
          <stop offset="0.554688" stopColor="#FE86BD" />
          <stop offset="0.558594" stopColor="#FE86BE" />
          <stop offset="0.5625" stopColor="#FE87BE" />
          <stop offset="0.566406" stopColor="#FE87BF" />
          <stop offset="0.570313" stopColor="#FE88BF" />
          <stop offset="0.574219" stopColor="#FE88C0" />
          <stop offset="0.578125" stopColor="#FE89C0" />
          <stop offset="0.582031" stopColor="#FE89C1" />
          <stop offset="0.585938" stopColor="#FE8AC1" />
          <stop offset="0.589844" stopColor="#FE8AC1" />
          <stop offset="0.59375" stopColor="#FE8BC2" />
          <stop offset="0.597656" stopColor="#FE8BC2" />
          <stop offset="0.601562" stopColor="#FE8CC3" />
          <stop offset="0.605469" stopColor="#FE8CC3" />
          <stop offset="0.609375" stopColor="#FE8DC4" />
          <stop offset="0.613281" stopColor="#FE8DC4" />
          <stop offset="0.617188" stopColor="#FE8EC5" />
          <stop offset="0.621094" stopColor="#FE8FC5" />
          <stop offset="0.625" stopColor="#FE8FC6" />
          <stop offset="0.628906" stopColor="#FE90C6" />
          <stop offset="0.632813" stopColor="#FE90C7" />
          <stop offset="0.636719" stopColor="#FE91C7" />
          <stop offset="0.640625" stopColor="#FE91C8" />
          <stop offset="0.644531" stopColor="#FE92C8" />
          <stop offset="0.648438" stopColor="#FE92C9" />
          <stop offset="0.652344" stopColor="#FE93C9" />
          <stop offset="0.65625" stopColor="#FE93CA" />
          <stop offset="0.660156" stopColor="#FE94CA" />
          <stop offset="0.664063" stopColor="#FE94CB" />
          <stop offset="0.667969" stopColor="#FE95CB" />
          <stop offset="0.671875" stopColor="#FE95CC" />
          <stop offset="0.675781" stopColor="#FE96CC" />
          <stop offset="0.679688" stopColor="#FE96CD" />
          <stop offset="0.683594" stopColor="#FE97CD" />
          <stop offset="0.6875" stopColor="#FE97CE" />
          <stop offset="0.691406" stopColor="#FE98CE" />
          <stop offset="0.695312" stopColor="#FE98CE" />
          <stop offset="0.699219" stopColor="#FE99CF" />
          <stop offset="0.703125" stopColor="#FE99CF" />
          <stop offset="0.707031" stopColor="#FE9AD0" />
          <stop offset="0.710938" stopColor="#FE9AD0" />
          <stop offset="0.714844" stopColor="#FE9BD1" />
          <stop offset="0.71875" stopColor="#FE9CD1" />
          <stop offset="0.722656" stopColor="#FE9CD2" />
          <stop offset="0.726563" stopColor="#FE9DD2" />
          <stop offset="0.730469" stopColor="#FE9DD3" />
          <stop offset="0.734375" stopColor="#FE9ED3" />
          <stop offset="0.738281" stopColor="#FE9ED4" />
          <stop offset="0.742188" stopColor="#FE9FD4" />
          <stop offset="0.746094" stopColor="#FD9FD5" />
          <stop offset="0.75" stopColor="#FDA0D5" />
          <stop offset="0.753906" stopColor="#FDA0D6" />
          <stop offset="0.757813" stopColor="#FDA1D6" />
          <stop offset="0.761719" stopColor="#FDA1D7" />
          <stop offset="0.765625" stopColor="#FDA2D7" />
          <stop offset="0.769531" stopColor="#FDA2D8" />
          <stop offset="0.773438" stopColor="#FDA3D8" />
          <stop offset="0.777344" stopColor="#FDA3D9" />
          <stop offset="0.78125" stopColor="#FDA4D9" />
          <stop offset="0.785156" stopColor="#FDA4DA" />
          <stop offset="0.789063" stopColor="#FDA5DA" />
          <stop offset="0.792969" stopColor="#FDA5DB" />
          <stop offset="0.796875" stopColor="#FDA6DB" />
          <stop offset="0.800781" stopColor="#FDA6DB" />
          <stop offset="0.804688" stopColor="#FDA7DC" />
          <stop offset="0.808594" stopColor="#FDA8DC" />
          <stop offset="0.8125" stopColor="#FDA8DD" />
          <stop offset="0.816406" stopColor="#FDA9DD" />
          <stop offset="0.820312" stopColor="#FDA9DE" />
          <stop offset="0.824219" stopColor="#FDAADE" />
          <stop offset="0.828125" stopColor="#FDAADF" />
          <stop offset="0.832031" stopColor="#FDABDF" />
          <stop offset="0.835938" stopColor="#FDABE0" />
          <stop offset="0.839844" stopColor="#FDACE0" />
          <stop offset="0.84375" stopColor="#FDACE1" />
          <stop offset="0.847656" stopColor="#FDADE1" />
          <stop offset="0.851563" stopColor="#FDADE2" />
          <stop offset="0.855469" stopColor="#FDAEE2" />
          <stop offset="0.859375" stopColor="#FDAEE3" />
          <stop offset="0.863281" stopColor="#FDAFE3" />
          <stop offset="0.867188" stopColor="#FDAFE4" />
          <stop offset="0.871094" stopColor="#FDB0E4" />
          <stop offset="0.875" stopColor="#FDB0E5" />
          <stop offset="0.878906" stopColor="#FDB1E5" />
          <stop offset="0.882813" stopColor="#FDB1E6" />
          <stop offset="0.886719" stopColor="#FDB2E6" />
          <stop offset="0.890625" stopColor="#FDB2E7" />
          <stop offset="0.894531" stopColor="#FDB3E7" />
          <stop offset="0.898437" stopColor="#FDB4E8" />
          <stop offset="0.902344" stopColor="#FDB4E8" />
          <stop offset="0.90625" stopColor="#FDB5E8" />
          <stop offset="0.910156" stopColor="#FDB5E9" />
          <stop offset="0.914062" stopColor="#FDB6E9" />
          <stop offset="0.917969" stopColor="#FDB6EA" />
          <stop offset="0.921875" stopColor="#FDB7EA" />
          <stop offset="0.925781" stopColor="#FDB7EB" />
          <stop offset="0.929688" stopColor="#FDB8EB" />
          <stop offset="0.933594" stopColor="#FDB8EC" />
          <stop offset="0.9375" stopColor="#FDB9EC" />
          <stop offset="0.941406" stopColor="#FDB9ED" />
          <stop offset="0.945312" stopColor="#FDBAED" />
          <stop offset="0.949219" stopColor="#FDBAEE" />
          <stop offset="0.953125" stopColor="#FDBBEE" />
          <stop offset="0.957031" stopColor="#FDBBEF" />
          <stop offset="0.960938" stopColor="#FDBCEF" />
          <stop offset="0.964844" stopColor="#FDBCF0" />
          <stop offset="0.96875" stopColor="#FDBDF0" />
          <stop offset="0.972656" stopColor="#FDBDF1" />
          <stop offset="0.976563" stopColor="#FDBEF1" />
          <stop offset="0.980469" stopColor="#FDBEF2" />
          <stop offset="0.984375" stopColor="#FDBFF2" />
          <stop offset="0.988281" stopColor="#FDBFF3" />
          <stop offset="0.992188" stopColor="#FDC0F3" />
          <stop offset="0.996094" stopColor="#FDC1F4" />
          <stop offset="1" stopColor="#FDC1F4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3269_4982"
          x1="1.10183"
          y1="59.6631"
          x2="142.297"
          y2="59.6631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCDB6" />
          <stop offset="0.0078125" stopColor="#FDCDB6" />
          <stop offset="0.015625" stopColor="#FDCCB6" />
          <stop offset="0.0234375" stopColor="#FDCBB6" />
          <stop offset="0.03125" stopColor="#FDCAB6" />
          <stop offset="0.0390625" stopColor="#FDC9B6" />
          <stop offset="0.046875" stopColor="#FDC8B6" />
          <stop offset="0.0546875" stopColor="#FDC7B6" />
          <stop offset="0.0625" stopColor="#FDC6B6" />
          <stop offset="0.0703125" stopColor="#FDC5B6" />
          <stop offset="0.078125" stopColor="#FDC4B6" />
          <stop offset="0.0859375" stopColor="#FDC3B7" />
          <stop offset="0.09375" stopColor="#FDC2B7" />
          <stop offset="0.101563" stopColor="#FDC1B7" />
          <stop offset="0.109375" stopColor="#FDC0B7" />
          <stop offset="0.117188" stopColor="#FDBFB7" />
          <stop offset="0.125" stopColor="#FDBEB7" />
          <stop offset="0.132812" stopColor="#FDBDB7" />
          <stop offset="0.140625" stopColor="#FDBCB7" />
          <stop offset="0.148438" stopColor="#FDBBB7" />
          <stop offset="0.15625" stopColor="#FDBAB7" />
          <stop offset="0.164062" stopColor="#FDB9B7" />
          <stop offset="0.171875" stopColor="#FDB8B7" />
          <stop offset="0.179688" stopColor="#FDB7B7" />
          <stop offset="0.1875" stopColor="#FDB6B7" />
          <stop offset="0.191406" stopColor="#FDB5B7" />
          <stop offset="0.195312" stopColor="#FDB4B7" />
          <stop offset="0.199219" stopColor="#FDB4B7" />
          <stop offset="0.203125" stopColor="#FDB3B7" />
          <stop offset="0.207031" stopColor="#FDB2B7" />
          <stop offset="0.210938" stopColor="#FDB2B7" />
          <stop offset="0.214844" stopColor="#FDB1B7" />
          <stop offset="0.21875" stopColor="#FDB1B7" />
          <stop offset="0.222656" stopColor="#FDB0B7" />
          <stop offset="0.226563" stopColor="#FDAFB7" />
          <stop offset="0.230469" stopColor="#FDAFB7" />
          <stop offset="0.234375" stopColor="#FDAEB7" />
          <stop offset="0.238281" stopColor="#FDADB7" />
          <stop offset="0.242188" stopColor="#FDADB7" />
          <stop offset="0.246094" stopColor="#FDACB7" />
          <stop offset="0.25" stopColor="#FDACB7" />
          <stop offset="0.253906" stopColor="#FDABB7" />
          <stop offset="0.257812" stopColor="#FDAAB7" />
          <stop offset="0.261719" stopColor="#FDAAB7" />
          <stop offset="0.265625" stopColor="#FDA9B7" />
          <stop offset="0.269531" stopColor="#FDA9B8" />
          <stop offset="0.273437" stopColor="#FDA8B8" />
          <stop offset="0.277344" stopColor="#FDA7B8" />
          <stop offset="0.28125" stopColor="#FDA7B8" />
          <stop offset="0.285156" stopColor="#FDA6B8" />
          <stop offset="0.289062" stopColor="#FDA5B8" />
          <stop offset="0.292969" stopColor="#FDA5B8" />
          <stop offset="0.296875" stopColor="#FDA4B8" />
          <stop offset="0.300781" stopColor="#FDA4B8" />
          <stop offset="0.304687" stopColor="#FDA3B8" />
          <stop offset="0.308594" stopColor="#FDA2B8" />
          <stop offset="0.3125" stopColor="#FDA2B8" />
          <stop offset="0.316406" stopColor="#FDA1B8" />
          <stop offset="0.320312" stopColor="#FDA1B8" />
          <stop offset="0.324219" stopColor="#FDA0B8" />
          <stop offset="0.328125" stopColor="#FD9FB8" />
          <stop offset="0.332031" stopColor="#FD9FB8" />
          <stop offset="0.335938" stopColor="#FD9EB8" />
          <stop offset="0.339844" stopColor="#FD9DB8" />
          <stop offset="0.34375" stopColor="#FD9DB8" />
          <stop offset="0.347656" stopColor="#FD9CB8" />
          <stop offset="0.351563" stopColor="#FD9CB8" />
          <stop offset="0.355469" stopColor="#FD9BB8" />
          <stop offset="0.359375" stopColor="#FD9AB8" />
          <stop offset="0.363281" stopColor="#FD9AB8" />
          <stop offset="0.367188" stopColor="#FE99B8" />
          <stop offset="0.371094" stopColor="#FE99B8" />
          <stop offset="0.375" stopColor="#FE98B8" />
          <stop offset="0.378906" stopColor="#FE97B8" />
          <stop offset="0.382813" stopColor="#FE97B8" />
          <stop offset="0.386719" stopColor="#FE96B8" />
          <stop offset="0.390625" stopColor="#FE95B8" />
          <stop offset="0.394531" stopColor="#FE95B8" />
          <stop offset="0.398438" stopColor="#FE94B8" />
          <stop offset="0.402344" stopColor="#FE94B8" />
          <stop offset="0.40625" stopColor="#FE93B8" />
          <stop offset="0.410156" stopColor="#FE92B8" />
          <stop offset="0.414063" stopColor="#FE92B8" />
          <stop offset="0.417969" stopColor="#FE91B8" />
          <stop offset="0.421875" stopColor="#FE90B8" />
          <stop offset="0.425781" stopColor="#FE90B8" />
          <stop offset="0.429688" stopColor="#FE8FB8" />
          <stop offset="0.433594" stopColor="#FE8FB8" />
          <stop offset="0.4375" stopColor="#FE8EB8" />
          <stop offset="0.441406" stopColor="#FE8DB8" />
          <stop offset="0.445313" stopColor="#FE8DB8" />
          <stop offset="0.449219" stopColor="#FE8CB8" />
          <stop offset="0.453125" stopColor="#FE8CB9" />
          <stop offset="0.457031" stopColor="#FE8BB9" />
          <stop offset="0.460938" stopColor="#FE8AB9" />
          <stop offset="0.464844" stopColor="#FE8AB9" />
          <stop offset="0.46875" stopColor="#FE89B9" />
          <stop offset="0.472656" stopColor="#FE88B9" />
          <stop offset="0.476562" stopColor="#FE88B9" />
          <stop offset="0.480469" stopColor="#FE87B9" />
          <stop offset="0.484375" stopColor="#FE87B9" />
          <stop offset="0.488281" stopColor="#FE86B9" />
          <stop offset="0.492188" stopColor="#FE85B9" />
          <stop offset="0.496094" stopColor="#FE85B9" />
          <stop offset="0.5" stopColor="#FE84B9" />
          <stop offset="0.503906" stopColor="#FE84B9" />
          <stop offset="0.507812" stopColor="#FE83B9" />
          <stop offset="0.511719" stopColor="#FE82B9" />
          <stop offset="0.515625" stopColor="#FE82B9" />
          <stop offset="0.523437" stopColor="#FE82B9" />
          <stop offset="0.527344" stopColor="#FE82BA" />
          <stop offset="0.53125" stopColor="#FE83BA" />
          <stop offset="0.535156" stopColor="#FE83BB" />
          <stop offset="0.539062" stopColor="#FE84BB" />
          <stop offset="0.542969" stopColor="#FE84BC" />
          <stop offset="0.546875" stopColor="#FE85BC" />
          <stop offset="0.550781" stopColor="#FE85BD" />
          <stop offset="0.554687" stopColor="#FE86BD" />
          <stop offset="0.558594" stopColor="#FE86BE" />
          <stop offset="0.5625" stopColor="#FE87BE" />
          <stop offset="0.566406" stopColor="#FE87BF" />
          <stop offset="0.570312" stopColor="#FE88BF" />
          <stop offset="0.574219" stopColor="#FE88C0" />
          <stop offset="0.578125" stopColor="#FE89C0" />
          <stop offset="0.582031" stopColor="#FE89C1" />
          <stop offset="0.585938" stopColor="#FE8AC1" />
          <stop offset="0.589844" stopColor="#FE8AC1" />
          <stop offset="0.59375" stopColor="#FE8BC2" />
          <stop offset="0.597656" stopColor="#FE8BC2" />
          <stop offset="0.601562" stopColor="#FE8CC3" />
          <stop offset="0.605469" stopColor="#FE8CC3" />
          <stop offset="0.609375" stopColor="#FE8DC4" />
          <stop offset="0.613281" stopColor="#FE8DC4" />
          <stop offset="0.617188" stopColor="#FE8EC5" />
          <stop offset="0.621094" stopColor="#FE8FC5" />
          <stop offset="0.625" stopColor="#FE8FC6" />
          <stop offset="0.628906" stopColor="#FE90C6" />
          <stop offset="0.632812" stopColor="#FE90C7" />
          <stop offset="0.636719" stopColor="#FE91C7" />
          <stop offset="0.640625" stopColor="#FE91C8" />
          <stop offset="0.644531" stopColor="#FE92C8" />
          <stop offset="0.648438" stopColor="#FE92C9" />
          <stop offset="0.652344" stopColor="#FE93C9" />
          <stop offset="0.65625" stopColor="#FE93CA" />
          <stop offset="0.660156" stopColor="#FE94CA" />
          <stop offset="0.664062" stopColor="#FE94CB" />
          <stop offset="0.667969" stopColor="#FE95CB" />
          <stop offset="0.671875" stopColor="#FE95CC" />
          <stop offset="0.675781" stopColor="#FE96CC" />
          <stop offset="0.679688" stopColor="#FE96CD" />
          <stop offset="0.683594" stopColor="#FE97CD" />
          <stop offset="0.6875" stopColor="#FE97CE" />
          <stop offset="0.691406" stopColor="#FE98CE" />
          <stop offset="0.695312" stopColor="#FE98CE" />
          <stop offset="0.699219" stopColor="#FE99CF" />
          <stop offset="0.703125" stopColor="#FE99CF" />
          <stop offset="0.707031" stopColor="#FE9AD0" />
          <stop offset="0.710938" stopColor="#FE9AD0" />
          <stop offset="0.714844" stopColor="#FE9BD1" />
          <stop offset="0.71875" stopColor="#FE9CD1" />
          <stop offset="0.722656" stopColor="#FE9CD2" />
          <stop offset="0.726562" stopColor="#FE9DD2" />
          <stop offset="0.730469" stopColor="#FE9DD3" />
          <stop offset="0.734375" stopColor="#FE9ED3" />
          <stop offset="0.738281" stopColor="#FE9ED4" />
          <stop offset="0.742188" stopColor="#FE9FD4" />
          <stop offset="0.746094" stopColor="#FD9FD5" />
          <stop offset="0.75" stopColor="#FDA0D5" />
          <stop offset="0.753906" stopColor="#FDA0D6" />
          <stop offset="0.757813" stopColor="#FDA1D6" />
          <stop offset="0.761719" stopColor="#FDA1D7" />
          <stop offset="0.765625" stopColor="#FDA2D7" />
          <stop offset="0.769531" stopColor="#FDA2D8" />
          <stop offset="0.773438" stopColor="#FDA3D8" />
          <stop offset="0.777344" stopColor="#FDA3D9" />
          <stop offset="0.78125" stopColor="#FDA4D9" />
          <stop offset="0.785156" stopColor="#FDA4DA" />
          <stop offset="0.789063" stopColor="#FDA5DA" />
          <stop offset="0.792969" stopColor="#FDA5DB" />
          <stop offset="0.796875" stopColor="#FDA6DB" />
          <stop offset="0.800781" stopColor="#FDA6DB" />
          <stop offset="0.804688" stopColor="#FDA7DC" />
          <stop offset="0.808594" stopColor="#FDA8DC" />
          <stop offset="0.8125" stopColor="#FDA8DD" />
          <stop offset="0.816406" stopColor="#FDA9DD" />
          <stop offset="0.820313" stopColor="#FDA9DE" />
          <stop offset="0.824219" stopColor="#FDAADE" />
          <stop offset="0.828125" stopColor="#FDAADF" />
          <stop offset="0.832031" stopColor="#FDABDF" />
          <stop offset="0.835938" stopColor="#FDABE0" />
          <stop offset="0.839844" stopColor="#FDACE0" />
          <stop offset="0.84375" stopColor="#FDACE1" />
          <stop offset="0.847656" stopColor="#FDADE1" />
          <stop offset="0.851563" stopColor="#FDADE2" />
          <stop offset="0.855469" stopColor="#FDAEE2" />
          <stop offset="0.859375" stopColor="#FDAEE3" />
          <stop offset="0.863281" stopColor="#FDAFE3" />
          <stop offset="0.867188" stopColor="#FDAFE4" />
          <stop offset="0.871094" stopColor="#FDB0E4" />
          <stop offset="0.875" stopColor="#FDB0E5" />
          <stop offset="0.878906" stopColor="#FDB1E5" />
          <stop offset="0.882813" stopColor="#FDB1E6" />
          <stop offset="0.886719" stopColor="#FDB2E6" />
          <stop offset="0.890625" stopColor="#FDB2E7" />
          <stop offset="0.894531" stopColor="#FDB3E7" />
          <stop offset="0.898438" stopColor="#FDB3E8" />
          <stop offset="0.902344" stopColor="#FDB4E8" />
          <stop offset="0.90625" stopColor="#FDB5E8" />
          <stop offset="0.910156" stopColor="#FDB5E9" />
          <stop offset="0.914063" stopColor="#FDB6E9" />
          <stop offset="0.917969" stopColor="#FDB6EA" />
          <stop offset="0.921875" stopColor="#FDB7EA" />
          <stop offset="0.925781" stopColor="#FDB7EB" />
          <stop offset="0.929688" stopColor="#FDB8EB" />
          <stop offset="0.933594" stopColor="#FDB8EC" />
          <stop offset="0.9375" stopColor="#FDB9EC" />
          <stop offset="0.941406" stopColor="#FDB9ED" />
          <stop offset="0.945312" stopColor="#FDBAED" />
          <stop offset="0.949219" stopColor="#FDBAEE" />
          <stop offset="0.953125" stopColor="#FDBBEE" />
          <stop offset="0.957031" stopColor="#FDBBEF" />
          <stop offset="0.960938" stopColor="#FDBCEF" />
          <stop offset="0.964844" stopColor="#FDBCF0" />
          <stop offset="0.96875" stopColor="#FDBDF0" />
          <stop offset="0.972656" stopColor="#FDBDF1" />
          <stop offset="0.976562" stopColor="#FDBEF1" />
          <stop offset="0.980469" stopColor="#FDBEF2" />
          <stop offset="0.984375" stopColor="#FDBFF2" />
          <stop offset="0.988281" stopColor="#FDBFF3" />
          <stop offset="0.992188" stopColor="#FDC0F3" />
          <stop offset="0.996094" stopColor="#FDC1F4" />
          <stop offset="1" stopColor="#FDC1F4" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
